import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, NavLink } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import mermaid from 'mermaid';
import BlogPost from './components/BlogPost';
import BlogPosts from './components/BlogPosts';
import Sidebar from './components/Sidebar';
import LessonSlider from './components/LessonSlider';
import './style.css';
import './custom.css';

function App() {
  const [topLevelLessons, setTopLevelLessons] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    fetch('/api/lessons/top-level')
      .then(response => response.json())
      .then(data => setTopLevelLessons(data));

    mermaid.initialize({
      startOnLoad: true,
      theme: 'default', 
    });
  }, []);

  return (
    <HelmetProvider>
      <Helmet defaultTitle="Simone Di Somma - Blog">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
        <meta name="description" content="Personal blog and thoughts by Simone Di Somma" />
        <link rel="canonical" href="https://simonedisomma.com" />
      </Helmet>
      <Router basename="/">
        <div className="container-fluid px-0">
          <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
          </Helmet>

          <header className="py-2 mb-3 border-bottom">
            <div className="container">
              <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                <Link to="/" className="d-flex align-items-center text-decoration-none mb-2 mb-md-0 flex-shrink-0">
                  <img 
                    src="/static/img/headshot_simonedisomma.jpg" 
                    alt="Simone Di Somma" 
                    className="rounded-circle me-2" 
                    width="40" 
                    height="40"
                  />
                  <span className="fs-4 p-1 invertito text-nowrap">Simone Di Somma</span>
                </Link>
                
                <button 
                  className="navbar-toggler d-md-none" 
                  type="button" 
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <nav className={`w-100 w-md-auto ${isMobileMenuOpen ? 'show' : ''}`}>
                  <ul className="nav nav-pills custom-nav justify-content-center justify-content-md-end">
                    <li className="nav-item">
                      <NavLink 
                        to="/" 
                        className={({ isActive }) => 
                          isActive ? "nav-link active custom-link" : "nav-link custom-link"
                        } 
                        end
                      >
                        Blog
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink 
                        to="/blog-posts" 
                        className={({ isActive }) => 
                          isActive ? "nav-link active custom-link" : "nav-link custom-link"
                        }
                      >
                        Posts
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink 
                        to="/post/about-me" 
                        className={({ isActive }) => 
                          isActive ? "nav-link active custom-link" : "nav-link custom-link"
                        }
                      >
                        About me
                      </NavLink>
                    </li>
                    <li className="nav-item dropdown">
                      <a 
                        className="nav-link dropdown-toggle custom-link" 
                        href="#" 
                        id="lessonsDropdown" 
                        role="button" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false"
                      >
                        Lessons
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="lessonsDropdown">
                        {topLevelLessons.map((lesson) => (
                          <li key={lesson.id}>
                            <Link 
                              to={`/lesson/${lesson.slug}`} 
                              className="dropdown-item custom-link"
                              onClick={() => setIsMobileMenuOpen(false)}
                            >
                              {lesson.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </header>

          <div className="container">
            <Routes>
              <Route 
                path="/" 
                element={
                  <div className="row g-4">
                    <div className="col-lg-8 order-lg-2">
                      <BlogPost />
                    </div>
                    <div className="col-lg-4 order-lg-1">
                      <Sidebar />
                    </div>
                  </div>
                } 
              />
              <Route 
                path="/blog-posts" 
                element={
                  <div className="row g-4">
                    <div className="col-lg-8 order-lg-2">
                      <BlogPosts />
                    </div>
                    <div className="col-lg-4 order-lg-1">
                      <Sidebar />
                    </div>
                  </div>
                } 
              />
              <Route 
                path="/post/:slug" 
                element={
                  <div className="row g-4">
                    <div className="col-lg-8 order-lg-2">
                      <BlogPost />
                    </div>
                    <div className="col-lg-4 order-lg-1">
                      <Sidebar />
                    </div>
                  </div>
                } 
              />
              <Route path="/lesson/:lessonId" element={<LessonSlider />} />
              <Route path="*" element={<BlogPost />} />
            </Routes>
          </div>

          <footer className="bg-light text-center py-3 mt-4">
            <p className="mb-0">Copyright © <strong>2000</strong> ↔ <strong>2024</strong> <strong>Simone Di Somma</strong> 🚀</p>
          </footer>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;