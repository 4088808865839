import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function BlogPost() {
  const [post, setPost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    fetch(`/api/post/${slug || ''}`)
      .then(response => response.json())
      .then(data => {
        console.log('Post data received:', data);
        if (data && typeof data === 'object') {
          setPost(data);
        }
      })
      .catch(error => {
        console.error('Error fetching post:', error);
      });
  }, [slug]);

  if (!post) return <div className="loading-spinner">Loading...</div>;

  // Extract the first heading from content
  const getFirstHeading = (content) => {
    const match = content.match(/<h[1-3][^>]*>(.*?)<\/h[1-3]>/i);
    return match ? match[1].replace(/<[^>]*>/g, '').trim() : null;
  };

  // Try to get title from first heading, fallback to post.title if no heading found
  const cleanTitle = getFirstHeading(post.content) || 
                    (post.title ? post.title.replace(/<[^>]*>/g, '').trim() : '');
  const pageTitle = cleanTitle ? `${cleanTitle} - Simone Di Somma` : 'Blog - Simone Di Somma';

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={post.description || `${cleanTitle} - Blog post by Simone Di Somma`} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={post.description || `${cleanTitle} - Blog post by Simone Di Somma`} />
        <meta property="og:type" content="article" />
        <link rel="canonical" href={`https://simonedisomma.com${slug ? `/post/${slug}` : ''}`} />
      </Helmet>
      <div className="bg-white p-4 rounded shadow-sm main-content">
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </div>
    </>
  );
}

export default BlogPost;