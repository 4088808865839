import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

function Sidebar() {
  const [recentPosts, setRecentPosts] = useState([]);
  const location = useLocation();

  useEffect(() => {
    console.log('Fetching sidebar data...');
    fetch('/api/sidebar')
      .then(response => response.json())
      .then(data => {
        console.log('Sidebar data received:', data);
        setRecentPosts(data);
      })
      .catch(error => console.error('Error fetching sidebar data:', error));
  }, []);

  console.log('Rendering sidebar with posts:', recentPosts);

  const showRecentPosts = location.pathname !== '/blog-posts';

  return (
    <div className="sidebar">
      {showRecentPosts && (
        <>
          <h3>Recent Posts</h3>
          {recentPosts.length === 0 ? (
            <p>Loading recent posts...</p>
          ) : (
            <>
              <ul className="recent-posts-list">
                {recentPosts.map(post => (
                  <li key={post.slug}>
                    <Link to={`/post/${post.slug}`} className="sidebar-link">{post.title}</Link>
                  </li>
                ))}
              </ul>
              <Link to="/blog-posts" className="sidebar-link view-all-posts">View all posts</Link>
            </>
          )}
        </>
      )}
      
      <div className="connect-with-me">
        <h2>Connect with me</h2>
        <div className="social-links">
          <a href="https://linkedin.com/in/simonedisomma" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} className="p-1" />
          </a>
          <a href="https://instagram.com/simonedisomma" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} className="p-1" />
          </a>
          <a href="https://facebook.com/simonedisomma" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} className="p-1" />
          </a>
          <a href="https://twitter.com/simonedisomma" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} className="p-1" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;